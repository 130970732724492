body {
  margin: 0;
  overflow-x: hidden;
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #666;
  z-index: 1;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 5px;
}

.Resizer {
  z-index: 1;
}

.logo {
  animation: fadein 5s;
}

@keyframes fadein {
   from { opacity: 0 }
   to { opacity: 1 }
}
