.light-theme-box {
  width: 100%;
  margin: 50px auto;
  background-color: #ffffff;
  position: relative;
  
  /* Creating a pseudo-element for the vibrant border effect */
  &:before {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      background: linear-gradient(45deg, #0009e8, #fad0c4, #00007f, #ff9a9e);
      z-index: -1;
      border-radius: 13px; /* slightly more than the box to ensure the gradient border is fully visible */
  }

  /* Box shadow for depth and pop */
  box-shadow: 
      0 4px 8px rgba(0, 0, 0, 0.1), /* More pronounced base shadow */
      0 0 10px rgba(255, 255, 255, 1), /* Strong white glow */
      inset 0 1px 2px rgba(0, 0, 0, 0.1); /* Inner shadow */

  border-radius: 8px;
  
  transition: all 0.3s ease;
}

.light-theme-box:hover {
  transform: translateY(-3px); /* Slight lift effect on hover */
  box-shadow: 
      0 7px 14px rgba(0, 0, 0, 0.15),
      0 0 15px rgba(255, 255, 255, 1),
      inset 0 2px 3px rgba(0, 0, 0, 0.15);
}

.dark-theme-box {
  width: 100%;
  margin: 50px auto;
  background-color: #121212; /* Dark background color */
  position: relative;
  
  /* Pseudo-element for the vibrant border effect */
  &:before {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      background: linear-gradient(45deg, #ff2a6d, #0009e8, #00007f, #ff2a6d);
      z-index: -1;
      border-radius: 13px;
      filter: blur(3px); /* Blur for a neon glow effect */
  }

  /* Box shadow for depth and pop */
  box-shadow: 
      0 4px 8px rgba(255, 255, 255, 0.1), /* Light shadow on dark background */
      inset 0 0 10px rgba(255, 255, 255, 0.1); /* Inner glow for depth */

  border-radius: 8px;
  
  transition: all 0.3s ease;
}

.dark-theme-box:hover {
  transform: translateY(-3px); /* Slight lift effect on hover */
  box-shadow: 
      0 7px 14px rgba(255, 255, 255, 0.15),
      inset 0 0 15px rgba(255, 255, 255, 0.15);
}
